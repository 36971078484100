import * as Sentry from "@sentry/angular-ivy";
import { Subject, shareReplay, Observable, catchError, of } from "rxjs";

export class LoadingWrapper<T> {
  private readonly _errorLoading$ = new Subject<boolean>();
  readonly errorLoading$ = this._errorLoading$.pipe(shareReplay(1));
  readonly data$: Observable<T>;

  constructor(data: Observable<T>) {
    this.data$ = data.pipe(
      shareReplay(1),
      catchError(error => {
        console.error(error);
        Sentry.captureException(error);
        this._errorLoading$.next(true);
        return of();
      })
    );
  }
}

export class LoadingWrapperWithError<T> {
  private readonly _errorLoading$ = new Subject<any>();
  readonly errorLoading$ = this._errorLoading$.pipe(shareReplay(1));
  readonly data$: Observable<T>;

  constructor(data: Observable<T>) {
    this.data$ = data
      .pipe(
        shareReplay(1),
        catchError((error) => {
          console.error(error);
          Sentry.captureException(error);
          this._errorLoading$.next(error);
          return of();
        })
      );
  }
}
